import { notification } from 'antd'
import sha256 from 'crypto-js/sha256'
import lodash, { get } from 'lodash'
import find from 'lodash/find'
import isEmpty from 'lodash/isEmpty'
import React, { Fragment } from 'react'

import { IdempotencyStorage } from '@System/hooks/useGeneratorKey'

import { trans } from './../i18n'

/**
 * Get the value of env param
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getEnv = (param, defaultValue = null) => lodash.get(process.env, param, defaultValue)

/**
 * Get global config
 *
 * @param param
 * @param defaultValue
 * @return mixed
 */
export const getConfig = (param, defaultValue = null) => lodash.get(window.APP_CONFIG, param, defaultValue)

/**
 * Create number sorter
 *
 * @param {String} param
 * @return {function(*, *): number}
 */
export const numberSorter = param => (a, b) => a[param] - b[param]

/**
 * Create string sorter
 *
 * @param {String} param
 * @return {function(*, *): number}
 */
export const stringSorter = param => (a, b) => {
    let aValue = a[param].toUpperCase()
    let bValue = b[param].toUpperCase()

    if (aValue < bValue) {
        return -1
    }

    if (aValue > bValue) {
        return 1
    }

    return 0
}

export const notifyErrorFromServer = (error, defaultMessage, prefix) => {
    let message = defaultMessage

    const code = error?.response?.data?.code
    const errors = error?.response?.data?.data

    if (code !== 'EXCEPTION' && lodash.isObject(errors) && errors && Object.keys(errors).length) {
        const firstErrorKey = Object.keys(errors)[0]
        let translatedErrors = []

        if (lodash.isObject(errors[firstErrorKey])) {
            for (const error of Object.keys(errors[firstErrorKey])) {
                translatedErrors.push(trans(`${prefix}.${firstErrorKey}.${error}`))
            }
        } else {
            translatedErrors = [errors[firstErrorKey]]
        }

        message = translatedErrors.map((translatedError, index) => (
            <Fragment key={index}>
                {translatedError}
                {index !== translatedErrors.length - 1 && <br />}
            </Fragment>
        ))
    }

    notification.error({
        message,
    })
}

export const downloadBlobData = (data, fileName, fileType) => {
    if (!data) {
        return
    }

    const downloadUrl = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.setAttribute('href', downloadUrl)
    link.setAttribute('download', `${fileName}.${fileType}`)

    document.body.appendChild(link)

    link.click()

    document.body.removeChild(link)
}

export const convertBlobDataToCsv = (data, fileName) => {
    downloadBlobData(data, fileName, 'csv')
}

export const convertBlobDataToExcel = (data, fileName) => {
    downloadBlobData(data, fileName, 'xlsx')
}

export const convertBlobDataToPDF = (data, fileName) => {
    downloadBlobData(data, fileName, 'pdf')
}

export const getVar = (object, param, defaultValue = null) => {
    let value = lodash.get(object, param)

    return value === undefined || value === null || value === '' ? defaultValue : value
}

export const renderImportErrors = (error, type) => {
    const line = lodash.get(error, 'line', '')
    const content = lodash.get(error, 'errors', {})
    let messages = []
    Object.entries(content).forEach(entry => {
        const [key, errors] = entry
        const listKeyException = ['has_many_in_merchant', 'warehouse_area']
        
        if (listKeyException.includes(key)) {
            messages.push(trans(`import_errors.${key}`))
        } else {
            Object.keys(errors).forEach(errorKey => {

                if(key === "sku_serial"){
                    messages.push(
                        trans(`document:sku_inventories_document.scan.errors.${key}.${errorKey}`)
                    )
                }else{
                    messages.push(
                        trans(`import_errors.${errorKey}`, {
                            attribute: trans(`${type}:label.${key}`),
                        })
                    )
                }
                
            })
        }
    })

    if (messages.length > 0) {
        return trans('import_errors.line', { line: line, content: messages.join(' ') })
    }

    return trans('import_errors.unknown_error')
}

export default function getTheme() {
    const domain = window.location.hostname
    const themeInfo = find(getConfig('THEMES', []), item => item.domains.includes(domain))
    return isEmpty(themeInfo) ? null : themeInfo
}

export const classes = (currentClass, restClass = {}) => {
    if (isEmpty(restClass)) return currentClass

    let newClass = ''

    Object.entries(restClass).map(([key, val]) => (newClass += val ? key + ' ' : ''))

    return currentClass + ' ' + newClass
}

export const isJson = str => {
    try {
        JSON.parse(str)
    } catch (e) {
        return false
    }
    return true
}

export const generatorHashKey = (data = {}) => {
    const key = get(IdempotencyStorage.getValue(), 'key')

    return sha256(JSON.stringify(data) + key).toString()
    
}


export const generatorHash = (data = {}, ...rest) => {
    return sha256(JSON.stringify(data) + JSON.stringify(rest)).toString()
}
