import { t } from '../../../system/i18n'

export const EVENTS = {
    IMPORT_ORDER_FROM_EXCEL: 'ORDER.IMPORT_ORDER_FROM_EXCEL',
    FETCH_ORDER_DETAIL_AGAIN: 'ORDER.FETCH_ORDER_DETAIL_AGAIN',
    CHANGE_SELLER: 'ORDER.CHANGE_SELLER',
    UPDATE_SHIPPING_PARTNER_SUCCESSFULL: 'ORDER.UPDATE_SHIPPING_PARTNER_SUCCESSFULL',
    RELOAD_ORDER_PREPARE_LIST: 'RELOAD_ORDER_PREPARE_LIST',
}
export const ORDER_STATUS = {
    WAITING_INSPECTION: 'WAITING_INSPECTION',
    WAITING_CONFIRM: 'WAITING_CONFIRM',
    WAITING_PROCESSING: 'WAITING_PROCESSING',
    WAITING_PICKING: 'WAITING_PICKING',
    WAITING_PACKING: 'WAITING_PACKING',
    WAITING_DELIVERY: 'WAITING_DELIVERY',
    DELIVERING: 'DELIVERING',
    DELIVERED: 'DELIVERED',
    CANCELED: 'CANCELED',
    RETURN: 'RETURN',
    RETURN_COMPLETED: 'RETURN_COMPLETED',
    FAILED_DELIVERY: 'FAILED_DELIVERY',
}
export const ORDER_STATUS_COLORS = {
    WAITING_INSPECTION: '#ff4559',
    WAITING_PROCESSING: '#722ed1',
    WAITING_PICKING: '#722ed1',
    WAITING_PACKING: '#ffa634',
    WAITING_CONFIRM: '#ed6e52',
    WAITING_DELIVERY: '#ffa634',
    DELIVERING: '#008df9',
    DELIVERED: '#09b8ad',
    FINISH: '#06d087',
    CANCELED: '#bdbdbd',
    RETURN: '#bd0313',
    RETURN_COMPLETED: '#06d087',
    FAILED_DELIVERY: '#ffa634',
}

export const PAYMENT_TYPE = {
    PAYMENT_TYPE_ADVANCE_PAYMENT: 'ADVANCE_PAYMENT', // Thanh toán trước
    PAYMENT_TYPE_COD: 'COD', // COD
    PAYMENT_TYPE_DEFERRED_PAYMENT: 'DEFERRED_PAYMENT', // Thanh toán sau
}

export const PAYMENT_METHOD = {
    METHOD_CASH: 'CASH', // Tiền mặt
    METHOD_BANK_TRANSFER: 'BANK_TRANSFER', // Chuyển khoản
}

// Validated rules khi tạo mới đơn hàng
export const VALIDATED_RULES = {
    code: [{ required: true, max: 255, type: 'string' }],
    merchant_id: [{ required: true, type: 'number' }],
    receiver_name: [{ required: true, max: 255, type: 'string' }],
    receiver_phone: [{ required: true, max: 255, type: 'string' }],
    receiver_address: [
        {
            required: true,
            message: t('validation:required', {
                attribute: t('order:label.receiver_address'),
            }),
        },
        { max: 255, type: 'string' },
    ],
    receiver_country_id: [{ required: true, type: 'number' }],
    product_quantity: [{ required: true, message: t('order:message.no_products') }],
}

// Giá trị khởi tạo khi tạo mới đơn hàng
export const INIT_ORDER = {
    code: '',
    merchant_id: undefined,
    order_amount: '', // Tiền hàng
    discount_amount: 0, // Số tiền chiết khấu
    shipping_amount: 0, // Phí vận chuyển
    total_amount: '', // Tổng tiền khách phải trả
    paid_amount: '', // Số tiền khách đã trả
    debit_amount: '', // Số tiền khách còn thiếu
    description: '', // Mô tả thêm cho đơn hàng
    receiver_name: '', //
    receiver_phone: '',
    receiver_country_id: undefined,
    receiver_province_id: undefined,
    receiver_district_id: undefined,
    receiver_ward_id: undefined,
    receiver_address: '',
    intended_delivery_at: '', // Ngày giao hàng dự kiến
    bank_account: '',
    bank_name: '',
    payment_amount: '', // Số tiền thanh toán
    payment_time: '', // Thời gian thanh toán
    payment_note: '', // Ghi chú thanh toán
    payment_type: PAYMENT_TYPE.PAYMENT_TYPE_COD,
    payment_method: '', // Phương thức thanh toán
    keyword: '',
    product_quantity: '',
}

export const ORDER_LOG_KEYS = {
    ORDER_CREATE: 'ORDER.CREATE',
    ORDER_UPDATE: 'ORDER.UPDATE',
    ORDER_CHANGE_STATUS: 'ORDER.CHANGE_STATUS',
    ORDER_INSPECTION: 'ORDER.INSPECTION',
    ORDER_CREATE_PACKAGE: 'ORDER.CREATE_PACKAGE',
    ORDER_PAYMENT_CONFIRM: 'ORDER.PAYMENT_CONFIRM',
    ORDER_CANCEL: 'ORDER.CANCEL',
    ORDER_CHANGE_FREIGHT_BILL: 'ORDER.CHANGE_FREIGHT_BILL',
    ORDER_CHANGE_PACKAGE_STATUS: 'ORDER.CHANGE_PACKAGE_STATUS',
    ORDER_UPDATE_SKUS: 'ORDER.UPDATE_SKUS',
    CHANGE_SHIPPING_PARTNER: 'ORDER.CHANGE_SHIPPING_PARTNER',
    ORDER_REMOVE_WAREHOUSE_AREA: 'ORDER.REMOVE_WAREHOUSE_AREA',
    ORDER_ADD_WAREHOUSE_AREA: 'ORDER.ADD_WAREHOUSE_AREA',
    ORDER_ADD_PRIORITY: 'ORDER.ADD_PRIORITY',
    ORDER_UPDATE_ATTRIBUTES: 'ORDER.UPDATE_ATTRIBUTES',
    ORDER_UPDATE_EXPECTED_TRANSPORTING_PRICE: 'ORDER.UPDATE_EXPECTED_TRANSPORTING_PRICE',
    ORDER_UPDATE_BATCH: 'ORDER.UPDATE_BATCH',
    ORDER_COMPLETE_BATCH: 'ORDER.COMPLETE_BATCH',
}

export const ORDER_PACKING_STATUS = {
    WAITING_PROCESSING: {
        key: 'WAITING_PROCESSING',
        name: 'WAITING_PROCESSING',
        color: '#1890FF',
        text_name: t('order:status.WAITING_PROCESSING'),
    },
    WAITING_PICKING: {
        key: 'WAITING_PICKING',
        name: 'WAITING_PICKING',
        color: '#1890FF',
        text_name: t('order:status.WAITING_PICKING'),
    },
    WAITING_PACKING: {
        key: 'WAITING_PACKING',
        name: 'WAITING_PACKING',
        color: '#1890FF',
        text_name: t('order:status.WAITING_PACKING'),
    },
    // WAITING_CONFIRM: {key: "WAITING_CONFIRM", name: "WAITING_CONFIRM", color: "#1890FF", text_name: t("order:status.WAITING_CONFIRM")},
    WAITING_DELIVERY: {
        key: 'WAITING_DELIVERY',
        name: 'WAITING_DELIVERY',
        color: '#1890FF',
        text_name: t('order:status.WAITING_DELIVERY'),
    },
    DELIVERING: { key: 'DELIVERING', name: 'DELIVERING', color: '#090a0a', text_name: t('order:status.DELIVERING') },
    DELIVERED: { key: 'DELIVERED', name: 'DELIVERED', color: '#090a0a', text_name: t('order:status.DELIVERED') },
    CANCELED: { key: 'CANCELED', name: 'CANCELED', color: '#ff021b', text_name: t('order:status.CANCELED') },
    RETURN: { key: 'RETURN', name: 'RETURN', color: '#ff021b', text_name: t('order:status.RETURN') },
    RETURN_COMPLETED: {
        key: 'RETURN_COMPLETED',
        name: 'RETURN_COMPLETED',
        color: '#ff021b',
        text_name: t('order:status.RETURN_COMPLETED'),
    },
    ERROR_TRACKING: {
        key: 'error_tracking',
        name: 'error_tracking',
        color: '#ff021b',
        text_name: t('order:status.error_tracking'),
    },
}

export const EXPORT_ORDER_STATUS = {
    NEW: { key: 'new', name: 'new', color: '#1890FF', text_name: t('order:export_order_status.new') },
    PROCESS: { key: 'process', name: 'process', color: '#87d068', text_name: t('order:export_order_status.process') },
    FINISHED: {
        key: 'finished',
        name: 'finished',
        color: '#ff4559',
        text_name: t('order:export_order_status.finished'),
    },
    CANCELED: {
        key: 'canceled',
        name: 'canceled',
        color: '#090a0a',
        text_name: t('order:export_order_status.canceled'),
    },
    ERROR: { key: 'error', name: 'error', color: '#ff021b', text_name: t('order:export_order_status.error') },
}

export const SHIPPING_PARTNER_CODE_ALLOW_PRINT_BY_API = ['JNTP', 'LWE', 'SAPI', 'FLASH', 'JNTM', 'SNAPPY', 'GGE']
export const SHIPPING_PARTNER_CODE_ALLOW_EXPORT_FILE_CREATE_FREIGHT_BILL = ['JNTP', 'LWE']
export const ORDER_TAGS = {
    late_delivery_risk: { key: 'late_delivery_risk', color: 'volcano' },
    no_inspected: { key: 'late_delivery_risk', color: 'orange' },
    priority: { key: 'priority', color: 'purple' },
    inspected: { key: 'inspected', color: 'lime' },
}

export const MARKET_PLACE = {
    SHOPEE: 'SHOPEE',
    SHOPBASE: 'SHOPBASE',
    LAZADA: 'LAZADA',
    FOBIZ: 'FOBIZ',
    KIOTVIET: 'KIOTVIET',
    TIKTOKSHOP: 'TIKTOKSHOP',
    VELAONE: 'VELAONE',
    VELAONEPANCAKE: 'VELAONEPANCAKE',
    VELAONEECOMONE: 'VELAONEECOMONE',
    VELAONESHOPIFY: 'VELAONESHOPIFY',
    TIKI: 'TIKI',
    MANUAL: 'MANUAL',
    SAPO: 'SAPO',
}

export const MARKET_PLACE_ASYNC = ['TIKTOKSHOP', 'SHOPEE', 'LAZADA']
